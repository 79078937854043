@import "variables";
@import "~bootstrap/scss/bootstrap";

// GLOBAL

hr {
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  opacity: 1;
}

a {
  text-decoration: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
  background-color: silver;
  border-color: grey;
}

.slider.slider-horizontal .slider-tick,
.slider.slider-horizontal .slider-handle {
  margin-top: -1px !important;
}

.mesicni-splatka {
  text-align: center;
  background-color: transparent !important;
  border: none;
  font-size: 33px;
  font-weight: bold;
  opacity: 1; /* required on iOS */
}

#hypoteka_UcelUveru,
#hypoteka_ZdrojPrijmu {
  text-align: left;
}

#americka_hypoteka_ZdrojPrijmu {
  text-align: left;
}

#refinancovani_ZdrojPrijmu {
  text-align: left;
}

select {
  height: 38px;
  background: white;
}

label {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0.5rem;
}

.form-check label {
  font-weight: normal;
  margin-top: 0;
}

.tooltip-font {
  font-size: 13px !important;
  position: relative;
  top: -5px;
  cursor: help;
}

.small-msg {
  height: 1px;
}

.refinancovani-left {
  padding: 20px;
}

.refinancovani-right {
  padding: 20px;
}

.arrow-right {
  border-top: 10px solid;
  border-right: 10px solid;
  display: inline-block;
  padding: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#successMessage {
  text-align: center;
  position: fixed;
  top: 45%;
  z-index: 99;
  width: 100%;
  background: lightgrey;
  color: black;
  font-size: 26px;
  border: none;
  padding: 80px 20px;
}

.plus-minus {
  background: #fff;
  border: 1px solid #dad9de;
  padding: 0px;
  cursor: pointer;
  position: relative;
  right: 0px;
  float: right;
  top: -38px;
  margin-bottom: -38px;
}

.plus-minus span {
  display: block;
  height: 18px;
  line-height: 18px;
  width: 30px;
  text-align: center;
  cursor: pointer;
}

.plus-minus span.plus {
  border-bottom: 1px solid #eee;
}

.legal-example {
  margin-top: 20px;
  font-size: 14px;
}

#refinancovani_NovaMesicniSplatka {
  margin-top: -10px;
  margin-bottom: -15px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right calc(0.375em + 1.8rem) center;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-position: right calc(0.375em + 1.8rem) center;
}

.hide-result {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  color: grey !important;
}

.hide-result-msg {
  font-size: 10px;
  color: #dc3545;
  display: block;
  position: relative;
  margin-bottom: -24px;
  top: 16px;
}

.hide-result-msg-save {
  top: 66px;
}

.invisible {
  visibility: hidden;
}

.splatka-popis,
.splatka-small-popis {
  font-size: 10px;
  margin-top: -15px;
  display: block;
  margin-bottom: 30px;
}

#refinancovani_Rozdil {
  font-size: 22px;
  color: black;
}

.urokova-sazba {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #545b62;
}

.hide {
  display: none;
}

#label-priplatek {
  color: white;
  background-color: #dc3545;
  padding: 0 10px;
}

.disclaimer-text {
  font-size: 12px;
  margin-top: 10px;
  color: #545b62;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' data-prefix='far' data-icon='frown' class='svg-inline--fa fa-frown fa-w-16' role='img' viewBox='0 0 496 512'%3E%3Cpath fill='#dc3545' d='M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-80 128c-40.2 0-78 17.7-103.8 48.6-8.5 10.2-7.1 25.3 3.1 33.8 10.2 8.4 25.3 7.1 33.8-3.1 16.6-19.9 41-31.4 66.9-31.4s50.3 11.4 66.9 31.4c8.1 9.7 23.1 11.9 33.8 3.1 10.2-8.5 11.5-23.6 3.1-33.8C326 321.7 288.2 304 248 304z'/%3E%3C/svg%3E");
  background-size: 15px;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' data-prefix='far' data-icon='smile' class='svg-inline--fa fa-smile fa-w-16' role='img' viewBox='0 0 496 512'%3E%3Cpath fill='#28a745' d='M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z'/%3E%3C/svg%3E");
  background-size: 15px;
}

// LOGO a MENU

.back-to-website {
  width: 100%;
  text-align: right;
}

.back-to-website .btn {
  margin-top: 20px;
  margin-bottom: 20px;
}

.logo {
  margin-top: 20px;
  margin-bottom: 20px;
}

// HEADER BANNER

.header,
.header-overlay {
  height: 300px;
  position: relative;
}

.header {
  padding-top: 85px;
}

.header-overlay {
  position: absolute;
  width: 100%;
}

.header-text-1,
.header-text-2 {
  text-align: center;
}

.logo img {
  max-height: 50px;
}

// SERVICE MENU
.service-menu {
  position: relative;
  top: -45px;
}

.service-menu .d-flex {
  box-shadow: 0 0 60px black;
}

.service-menu div.p-2 {
  text-align: center;
  font-size: 24px;
  padding: 0 !important;
}

.service-menu div.p-2 a {
  display: block;
  padding: 25px;
}

// BANKS
.banks div.p-2 {
  text-align: center;
  min-width: 270px;
}

.banks .row div {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

// HELP BOX
.help-box {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 60px;
}

.help-box .h2 {
  color: white;
}

.help-text {
  margin-top: 5px;
  display: block;
}

.help-box a {
  color: white;
}

.help-box .container .row div.col-md-7 {
  margin: auto;
  justify-content: auto;
  justify-content: center;
  align-items: center;
}

.help-box .container .row div.col-md-5 {
  margin: 20px auto;
  justify-content: auto;
  justify-content: center;
  align-items: center;
}

.contact-item {
  margin: 10px;
}

// FOOTER
.footer {
  background-color: black;
  color: white;
  padding: 10px;
}

.footer__ewebovky {
  text-align: right;
  padding-bottom: 10px;
  padding-top: 50px;
}

.footer__ewebovky a {
  color: white;
}

.ewebovky-line {
  font-size: 12px;
}

.ewebovky-line img {
  height: 15px;
  vertical-align: middle;
  padding-left: 10px;
}

.footer a:hover {
  color: white;
}

#language-selector {
  position: relative;
  z-index: 10;
}

// TABLET
@media (max-width: 992px) {
  .arrow-right {
    margin-bottom: 25px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .contact-item span.h4 {
    font-size: 1.3em;
  }
}

// MOBILE landscape
@media (max-width: 768px) {
  .header-text-1 .h1,
  .header-text-2 .h1 {
    font-size: 20px !important;
  }

  .header-text-1,
  .header-text-2 {
    text-align: center;
  }

  .banks div.p-2 {
    min-width: auto;
  }

  .banks div.p-2 img {
    height: 26px;
    width: auto;
  }

  .h1,
  h1 {
    font-size: 2rem;
  }

  .service-menu div.p-2 {
    font-size: 18px;
  }

  .service-menu div.p-2 a {
    padding: 20px 25px;
  }
}

// MOBILE
@media (max-width: 576px) {
  .back-to-website {
    text-align: center;
  }

  .logo {
    text-align: center;
  }
}

// SAFARI
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .form-check-input {
      width: 22px;
    }
  }
}
