/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=latin-ext');

/**
 * General
 */
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-grid-classes: true;
$enable-print-styles: true;

$primary: black;
$text-color: #454545;
$complementary: #F2D492;
$contrary: #B8B08D;

// $theme-colors: (
//   primary: $primary,
//   complementary: $complementary,
//   contrary: $contrary,
//   text: $text-color,
//   gray: #9d9d9d,
//   white: #fff,
//   black: #000,
//   light: #f8faff,
//   darker: #666
// );

$input-border-color:                #ced4da;

$form-feedback-valid-color:         $input-border-color;
$form-feedback-invalid-color:       $input-border-color;

$form-feedback-icon-valid-color:    #198754;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color:  #dc3545;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
